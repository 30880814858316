<template>
  <div class="super">
    <div :class="['super__container', { mobileScreen: isMobile }]">
      <div class="df jc-sb ai-c">
        <SupervisorTitle title="Правила вызова авто с парковки" />
        <Icon
          v-if="!isMobile"
          name="IconSupervisorSwitchTable"
          width="85px"
          height="32px"
          class="pointer"
          @click.native="changeView"
        />
      </div>

      <div v-loading="isLoading" class="super__content">
        <div class="super__content-title">
          <div v-if="currentTerminal" class="supervisor-title__count-quota">
            Максимальное кол-во авто в буфере&nbsp;:&nbsp;
            {{ currentTerminal['buffer_zone_size'] }}
          </div>
          <div class="title__buttons">
            <iq-button icon="IconBell" @onClick="openParkingInfo">
              На парковке {{ totalParked }} авто
            </iq-button>

            <AddSupervisorQuotaButton v-if="!isMobile && isShowQuotas" />

            <iq-button
              v-if="!isMobile"
              color="error"
              :disabled="!isShowQuotas"
              @onClick="removeQuotas"
            >
              Удалить все правила
            </iq-button>
          </div>
        </div>

        <template v-if="isShowQuotas">
          <QuotaDay
            v-for="item in getKeysQuotaSortable"
            :key="item"
            :day-data="getQuota(item)"
          />
        </template>

        <template v-if="!isShowQuotas && !isLoading">
          <TableEmpty
            warning="У вас не создано ни одного правила вызова"
            proposition="Добавить правило вызова"
            @add="addQuota"
          />
        </template>
      </div>
    </div>

    <SupervisorParkingInfo />
    <DialogApplySupervisorSamples />
    <EditQuotaModal />
  </div>
</template>

<script>
import {
  DELETE_SUPERVISOR_QUOTA,
  FETCH_SUPERVISOR_QUOTAS_LINES,
  FETCH_SUPERVISOR_STATISTIC_TABLE,
  GET_IS_MOBILE,
  GET_LOADINGS_SUPERVISOR_STATISTIC,
  GET_SUPERVISOR_QUOTAS_FOR_TABLE,
  GET_SUPERVISOR_STATISTIC_TABLE,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
  SET_UPDATE_ACTION,
} from '@/store/actions'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  SUPERVISOR_EDIT_QUOTA,
  SUPERVISOR_PARKING_INFO,
} from '@/constants/dialogs'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AddSupervisorQuotaButton from '@/views/supervisor/components/AddSupervisorQuotaButton.vue'
import DialogApplySupervisorSamples from '@/views/supervisor/components/dialogs/dialogApplySamples/DialogApplySupervisorSamples.vue'
import EditQuotaModal from './components/dialogs/dialogTableEditQuota/EditQuotaModal.vue'
import Icon from '@/UI/icon/Icon.vue'
import IqButton from '@/views/ui/components/buttons/IqButton.vue'
import QuotaDay from './components/quota-day/QuotaDay.vue'
import SupervisorParkingInfo from './components/dialogs/dialogParkingInfo/SupervisorParkingInfo.vue'
import SupervisorTitle from './components/SupervisorTitle.vue'
import TableEmpty from '@/UI/table/table-empty/TableEmpty.vue'

export default {
  name: 'SupervisorTable',
  components: {
    QuotaDay,
    TableEmpty,
    IqButton,
    Icon,
    SupervisorTitle,
    SupervisorParkingInfo,
    AddSupervisorQuotaButton,
    DialogApplySupervisorSamples,
    EditQuotaModal,
  },
  computed: {
    ...mapGetters({
      cultureList: GET_CULTURE_FROM_STATE,
      terminalId: GET_TERMINAL_CURRENT_ID,
      terminalList: GET_TERMINAL_LIST,
      quotasList: GET_SUPERVISOR_QUOTAS_FOR_TABLE,
      statisticTable: GET_SUPERVISOR_STATISTIC_TABLE,
      isLoading: GET_LOADINGS_SUPERVISOR_STATISTIC,
      isMobile: GET_IS_MOBILE,
    }),
    currentTerminal() {
      if (this.terminalList.length > 0 && this.terminalId) {
        return this.terminalList.find(i => i.id === this.terminalId)
      }

      return null
    },
    isShowQuotas() {
      return Object.keys(this.quotasList).length > 0
    },
    getKeysQuotaSortable() {
      return Object.keys(this.quotasList).sort()
    },
    totalParked() {
      return this.statisticTable.reduce(
        (sum, item) => sum + item.total_count,
        0,
      )
    },
  },
  watch: {
    terminalId() {
      this.setAction(this.getData)
      this.getData()
    },
  },
  mounted() {
    this.setAction(this.getData)
    this.getData()
    localStorage.setItem('supervisor-page-format', 'AdminSupervisorTable')
  },
  destroyed() {
    this.setAction(() => ({}))
  },
  methods: {
    ...mapActions({
      getSupervisorStatisticTable: FETCH_SUPERVISOR_STATISTIC_TABLE,
      getSupervisorQuotasLines: FETCH_SUPERVISOR_QUOTAS_LINES,
      postDeleteQuotas: DELETE_SUPERVISOR_QUOTA,
      deleteSupervisorQuota: DELETE_SUPERVISOR_QUOTA,
    }),
    ...mapMutations({
      setAction: SET_UPDATE_ACTION,
    }),
    getData() {
      this.getSupervisorQuotasLines()
      this.getSupervisorStatisticTable()
    },
    changeView() {
      let route =
        this.$route.name === 'SupervisorTable'
          ? 'Supervisor'
          : 'AdminSupervisor'

      this.$router.push({ name: route })
    },
    getQuota(key) {
      return this.quotasList[key]
    },
    openParkingInfo() {
      this.setDialog({ name: SUPERVISOR_PARKING_INFO, visible: true })
    },
    removeQuotas() {
      this.$confirm('Вы хотите удалить все квоты?', 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          let removeIds = []

          for (let key in this.quotasList) {
            this.quotasList[key].forEach(item => {
              item.forEach(quota => removeIds.push(quota.id))
            })
          }
          this.deleteSupervisorQuota({ id: removeIds })
        })
        .catch(() => {})
    },
    addQuota() {
      this.setDialog({ name: SUPERVISOR_EDIT_QUOTA, visible: true })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./styles/table-and-samples"
</style>
